import React, { useState } from "react";
import './Home.css';
// import { Link } from "react-router-dom";

// Components
const NavBar = () => {
  const [stackOpen, setStackOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);

  const toggleMenu = (index) => {
    if (selectedItem === index) {
      setSelectedItem(-1); // Deselect if already selected
    } else {
      setSelectedItem(index); // Select the clicked item
    }
    setStackOpen(false); // Close the stack
  };

  return (
    <div className="navigationBar">
      <h1>A<span className="eyePurple">i</span> Lens</h1>
      <button type="button" onClick={() => setStackOpen(!stackOpen)} className="stackButton" id={stackOpen ? 'selected' : ''}><strong>☰</strong></button>
      <ul className={`menus ${stackOpen ? 'active' : ''}`}>
        <li onClick={() => toggleMenu(0)} id={selectedItem === 0 ? 'selected' : ''}>About Us</li>
        <li onClick={() => toggleMenu(1)} id={selectedItem === 1 ? 'selected' : ''}>Technology</li>
        <li onClick={() => toggleMenu(2)} id={selectedItem === 2 ? 'selected' : ''}>Contact Us</li>
        <li className="responsiveLogin" onClick={() => toggleMenu(3)} id={selectedItem === 3 ? 'selected' : ''}>Login</li>
      </ul>
      <button type="button" className="loginButton" onClick={() => toggleMenu(3)} id={selectedItem === 3 ? 'selected' : ''}>Login</button>
    </div>
  );
};

const GlassesModel = () => {
  <div className="glassesModel">
    
  </div>
};

const Lens = () => {
  <div className="lensModel">

  </div>
};

const Frame = () => {
  <div className="frameModel">
    
  </div>
};

const Home = () => {
  return (
    <div className="HomePage">
      <div className="backgroundImage"></div>
      <div className="content">
        <NavBar />
        <GlassesModel />
        <Lens />
        <Frame />
        <p><strong className="bottomText">Crisp | Convergent | Capable</strong></p>
      </div>
    </div>
  );
};


export default Home;